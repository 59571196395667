import React from 'react';

export default ({ type, text, close }) => {
    return (
        <>
            <div className={`modal-overlay notification-${type}`}>
                <p>{text}</p>
                <a onClick={close}>Close</a>
            </div>
        </>
    )
}
