import React from 'react';

export default ({ doPushupAction, isEnabled }) => {
    return (
        <>
            <button className="pushupButton" onClick={doPushupAction} disabled={isEnabled}>
                <p>DO PUSHUP</p>
            </button>
        </>
    );
}
